<template>
  <div class="analyse_month">
    <div class="width shadow">
      <div class="tableau no_margin" :class="{ load: load === false } ">
        <div class="ligne head" style="grid-template-columns: 10% 7% 12% 12% 9% 12% 9% 10% 9.5% 9.5%">
          <div class="e"></div>
          <div class="e">Entrées</div>
          <div class="e avant_red">Total</div>
          <div class="e avant_blue">Machines à sous</div>
          <div class="e">MAS B.P</div>
          <div class="e avant_turq">Roulettes électroniques</div>
          <div class="e">RAE B.P</div>
          <div class="e avant_green">Jeux traditionnels</div>
          <div class="e">Hors jeu payant</div>
          <div class="e">Hors jeu gratuit</div>
          <!--<div class="e">Jours</div>-->
        </div>
  
  
        <div class="ligne zoom_color white2" style="grid-template-columns: 10% 7% 12% 12% 9% 12% 9% 10% 9.5% 9.5%">
          <div class="e borderRight left">Total</div>
          <div class="e">{{ calc_col('enter_casino') | formatNumber }}</div>
          <div class="e avant_red euro" :class="color(total_total)">{{ total_total | formatNumberdecimal }}</div>
          <div class="e avant_blue euro" :class="color(calc_col('mas'))">{{ calc_col('mas') | formatNumberdecimal }}</div>
          <div class="e euro">{{ calc_col('bp_mas') | formatNumberdecimal }}</div>
          <div class="e avant_turq euro" :class="color(calc_col('rae'))">{{ calc_col('rae') | formatNumberdecimal }}</div>
          <div class="e euro">{{ calc_col('bp_rae') | formatNumberdecimal }}</div>
          <div class="e avant_green euro">{{ calc_col('jt') | formatNumberdecimalsimple }}</div>
          <div class="e euro">{{ calc_col('hj_payant') | formatNumberdecimal }}</div>
          <div class="e euro">{{ calc_col('hj_offert') | formatNumberdecimal }}</div>
          <!--<div class="e"></div>-->
        </div>
  
        <div class="ligne background2 border" style="grid-template-columns: 10% 7% 12% 12% 9% 12% 9% 10% 9.5% 9.5%" v-for="(n, k) in year_tab" :key="k">
          <router-link tag="div" :to="{ name: 'Analyse_month_key', params: { month: formatMonthKey(n.mo) } }" class="e borderRight background1 left no_border_bottom" style="font-weight: 400; cursor: pointer">{{ $dayjs(n.mo).format('MMMM YY') }}</router-link>
          <div class="e"><number :value="n.enter_casino" round="formatNumber" :oldValue="old_year_tab[n.mm] ? old_year_tab[n.mm].enter_casino : 0" :variation="true"></number></div>
          <div class="e avant_red">
            <number :value="n.mas + n.rae + n.jt + parseFloat(n.hj_payant)" round="formatNumberdecimal" :euro="true" :variation="true" :oldValue="old_year_tab[n.mm] ? old_year_tab[n.mm].mas + old_year_tab[n.mm].rae + old_year_tab[n.mm].jt + old_year_tab[n.mm].hj_payant : 0"></number>
          </div>
          <div class="e avant_blue"><number :value="n.mas" round="formatNumberdecimal" :euro="true" :oldValue="old_year_tab[n.mm] ? old_year_tab[n.mm].mas : 0" :variation="true"></number></div>
          <div class="e not_important euro">{{ n.bp_mas | formatNumberdecimal }}</div>
          <div class="e avant_turq"><number :value="n.rae" round="formatNumberdecimal" :euro="true" :oldValue="old_year_tab[n.mm] ? old_year_tab[n.mm].rae : 0" :variation="true"></number></div>
          <div class="e not_important euro">{{ n.bp_rae | formatNumberdecimal }}</div>
          <div class="e avant_green"><number :value="n.jt" round="formatNumberdecimal" :euro="true" :oldValue="old_year_tab[n.mm] ? old_year_tab[n.mm].jt : 0" :variation="true"></number></div>
          <div class="e euro not_important">{{ n.hj_payant | formatNumberdecimal }}</div>
          <div class="e euro not_important">{{ n.hj_offert | formatNumberdecimal }}</div>
          <!--<div class="e not_important">{{ n.count }}</div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import number from "@/components/number";

import dayjs from 'dayjs'
require('dayjs/locale/fr')
dayjs.locale('fr')

export default {
  name: "analyse_month",
  components: {
    number
  },
  data() {
    return {
      year_tab: [],
      old_year_tab: {},

      load: false,

      year: 2021,

      total_total: 0,
    }
  },
  methods: {
    color(n) {
      if(Math.sign(n) === -1) return 'red'
      else if(n > 0) return 'green'
    },
    calc_col(key) {
      let r = 0
      for(let n in this.year_tab) {
        if(this.year_tab[n][key])
          r += parseFloat(this.year_tab[n][key])
      }
      return r
    },
    formatMonthKey(at) {
      return dayjs(at).format('MM-YYYY')
    }
  },
  created() {

    if(this.$route.params.year)
      this.year = this.$route.params.year

    this.$httpStatum.get('/year/' + this.year).then((response) => {
      this.year_tab = response.data
      
      // for(let n in response.data) {
      //   this.year_tab[dayjs(response.data[n].mo).format('MM')] = response.data[n]
      // }
      
      console.log('2020', this.year_tab)

      this.total_total = this.calc_col('jt') + this.calc_col('mas') + this.calc_col('rae') + this.calc_col('hj_payant')

      for(let n in this.year_tab) {
        if(!this.year_tab[n].hj_payant)
          this.year_tab[n].hj_payant = 0
          
        this.old_year_tab[this.year_tab[n].mm] = {}
      }
      
      this.$httpStatum.get('/year/' + (parseInt(this.year) - 1)).then((response) => {

        for(let n in response.data) {
          this.old_year_tab[response.data[n].mm] = response.data[n]
        }


        for(let n in this.old_year_tab) {
          if (!this.old_year_tab[n].hj_payant) this.old_year_tab[n].hj_payant = 0
        }

        console.log('2019', this.old_year_tab)
        this.load = true
        this.$forceUpdate();
      })
      
      this.load = true
    })

  }
}
</script>

<style scoped>


.analyse_month { margin: 100px 0px 0 0px; padding: 0 16px;}
.analyse_month .width { background: #fff  }
.ligne.head { border-bottom: 0; }
/*.tableau { width: 100%; display: table }*/
/*.ligne { display: table-row }*/
/*.ligne .e { display: table-cell; text-align: center }*/
/**/
/*.tableau .euro:after { top: 0 }*/

</style>